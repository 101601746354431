<template>
    <Loopee v-if="orgId === 1" @click="goHome" />
    <Ricom v-if="orgId === 2" @click="goHome" />
    <Kensol v-if="orgId === 3" @click="goHome" />
</template>

<script>
import Loopee from './logos/Loopee.vue'
import Ricom from './logos/Ricom.vue'
import Kensol from './logos/Kensol.vue'

export default {
  components: {
    Loopee,
    Ricom,
    Kensol
  },
  computed: {
    orgId () {
      return parseInt(process.env.VUE_APP_ORG_ID)
    }
  },
  methods: {
    goHome () {
      if (this.$route.name === 'add-device' ||
          this.$route.name === 'add-device-simple' ||
          this.$route.name === 'service' ||
          this.$route.name === 'inspection'
      ) {
        if (confirm('Czy na pewno chcesz przerwać proces i przejść do menu głównego?')) {
          this.$router.push({name: 'home'})
        }
      } else {
        this.$router.push({name: 'home'})
      }
    }
  }
};
</script>
